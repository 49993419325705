<template>
    <div class="dropdown-search">
        <i class="dropdown-search__icon fa-regular fa-magnifying-glass" />
        <input v-model="search"
               class="dropdown-search__input"
               type="text"
               :placeholder="placeholder">
        <i class="fa-solid fa-xmark dropdown-search__clear"
           :class="{'dropdown-search__clear--visible':search.length > 0}"
           @click="clearSearchInput"/>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    const props = withDefaults(defineProps<{
        modelValue: string,
        placeholder: string,
    }>(), {
        modelValue: '',
    });
    const emit = defineEmits(['update:modelValue']);

    const search = computed({
        get: () => props.modelValue,
        set: (newValue) => {
            emit('update:modelValue', newValue);
        },
    });

    const clearSearchInput = () => {
        emit('update:modelValue', '');
    };
</script>

<style scoped lang="scss">
@import "@/sass/partials/breakpoints";
@import "@/sass/design-system/variables";
@import "@/js/components/inputs/mixins/input";

.dropdown-search {
    @include input;

    padding: 12px 16px;

    @media (max-width: $md-breakpoint-max-width) {
        padding: 9px 14px;
    }

    &__icon {
        margin-right: 4px;
    }

    &__input {
        display: flex;
        flex: 1;

        /* Adding 1px to align iOS with Web */
        padding: 1px;
        border: none;
        background-color: transparent;
        color: $baseWhite;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.14px;
        outline: none;

        &::placeholder {
            color: $gray500;
            font-size: 16px;

            @media (max-width: $md-breakpoint-max-width) {
                font-size: 14px;
            }
        }
    }

    &__clear {
        margin-left: 4px;
        cursor: pointer;
        opacity: 0;

        &--visible {
            opacity: 1;
        }

        @media (min-width: $md-breakpoint) {
            display: none;
        }
    }
}
</style>
